document.addEventListener('DOMContentLoaded', () => {

  const mobileNav = document.querySelector('div.mobileNav')

  document.querySelector('div.mobileNavButton a').addEventListener('click', e => {
    e.preventDefault()
    if (mobileNav.classList.contains('show')) {
      mobileNav.classList.remove('show')
    } else {
      mobileNav.classList.add('show')
    }
  })

})